<template>
  <button
    class="group relative outline-none transition focus-visible:ring-2 active:scale-[0.98]"
    type="button"
    @click="onClick"
  >
    <transition
      enter-active-class="transition ease-out"
      enter-from-class="translate-y-3 opacity-0"
      leave-active-class="transition ease-out"
      leave-to-class="-translate-y-3 opacity-0"
    >
      <p
        v-show="showNotification"
        class="absolute -top-6 left-1/2 z-50 flex -translate-x-1/2 items-center justify-center gap-1 whitespace-nowrap rounded bg-white/70 p-1 font-sans text-xs text-success backdrop-blur"
      >
        <app-icon icon="ph:check" :size="16" />
        {{ $t("app.copy_text_success_message") }}
      </p>
    </transition>

    <slot>
      <app-icon
        class="text-subtle opacity-50 transition-opacity hover:opacity-100"
        icon="ph:copy-simple"
        :size="iconSize"
      />
    </slot>

    <app-icon
      v-if="copyIconOnHover"
      class="absolute -right-1 top-1/2 !hidden -translate-y-1/2 translate-x-full opacity-0 opacity-30 group-hover:!block"
      icon="ph:copy-simple"
      :size="20"
    />
  </button>
</template>

<script lang="ts" setup>
const properties = defineProps<{
  iconSize?: number;
  text: string;
  copyIconOnHover?: boolean;
}>();

const showNotification = ref(false);

const onClick = async () => {
  await navigator.clipboard.writeText(properties.text);
  showNotification.value = true;
  await timer(700);
  showNotification.value = false;
};
</script>
